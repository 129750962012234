<template>
  <v-container>
    <br />
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title class="white--text pl-5 pt-5">
            <h3 class="headline logo_text">
              <i class="fa fa-user logo_icon" /> &nbsp; {{ user.displayName }}
            </h3>
          </v-card-title>

          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <i class="fa fa-mail logo_icon" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ user.email }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-list-item>
              <v-list-item-action>
                <i class="fa fa-calendar logo_icon" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ user.creationDate }}</v-list-item-title>
                <v-list-item-subtitle>Creation Date</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-action />

              <v-list-item-content>
                <v-list-item-title>{{ user.creationDate }}</v-list-item-title>
                <v-list-item-subtitle>Last Logged In</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-list-item v-for="(map, idx) in mapTitles" :key="`map_${idx}`">
              <v-list-item-action>
                <i v-if="idx == 0" class="fa fa-map-o logo_icon" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ map }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="'/maps/' + map" icon>
                      <i class="fa fa-link" />
                    </v-btn>
                  </template>
                  <span>Edit '{{ map }}'</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>

            <v-divider inset />
            <v-list-item
              v-for="(map, idx) in publishedMaps"
              :key="`published_map_${idx}`"
            >
              <v-list-item-action>
                <i v-if="idx == 0" class="fa fa-share-square-o logo_icon" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ map }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="'/publish/' + map" icon>
                      <i class="fa fa-link" />
                    </v-btn>
                  </template>
                  <span>View '{{ map }}' Publish Details</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Account",
  computed: {
    ...mapGetters(["user", "mapTitles", "publishedMaps"])
  }
};
</script>
